// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import UserService from '@/services/user.service'
import TokenService from '@/services/token.service'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/views/Index'),
      redirect: '/dashboard',

      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          meta: { requireAuth: true, permLevel: 0 },
          component: () => import('@/views/Dashboard')
        },
        {
          path: '/pentests',
          name: 'Pentests',
          meta: { requireAuth: true, permLevel: 0 },
          component: () => import('@/views/Pentest')
        },
        {
          path: '/pentest/detail/:id',
          name: 'Pentest Details',
          meta: { requireAuth: true, permLevel: 0 },
          component: () => import('@/views/PentestDetail')
        },
        {
          path: '/pentest/detail/:id/files/:fileId',
          name: 'Pentest Detailsㅤ',
          meta: { requireAuth: true, permLevel: 0 },
          component: () => import('@/views/PentestDetail')
        },
        {
          path: '/pentest/detail/:id/files',
          name: 'Pentest Files',
          meta: { requireAuth: true, permLevel: 0 },
          component: () => import('@/views/PentestDetail')
        },
        {
          path: '/assets',
          name: 'Assets',
          meta: { requireAuth: true, permLevel: 0 },
          component: () => import('@/views/Assets')
        },
        {
          path: '/documents',
          name: 'Documents',
          meta: { requireAuth: true, permLevel: 0 },
          component: () => import('@/views/Documents')
        },
        {
          path: '/users',
          name: 'Users',
          meta: { requireAuth: true, permLevel: 1 },
          component: () => import('@/views/Users')
        },
        {
          path: '/contact-us',
          name: 'Contact us',
          meta: { requireAuth: true, permLevel: 0 },
          component: () => import('@/views/ContactUs')
        },
        {
          path: '/logs',
          name: 'Logs',
          meta: { requireAuth: true, permLevel: 1 },
          component: () => import('@/views/Logs')
        },
        {
          path: '/templates',
          name: 'Templates',
          meta: { requireAuth: true, permLevel: 4 },
          component: () => import('@/views/Templates')
        },
        {
          path: '/send-email',
          name: 'Send email',
          meta: { requireAuth: true, permLevel: 4 },
          component: () => import('@/views/SendEmail')
        },
        // {
        //   path: '/partnership',
        //   name: 'Partnership',
        //   meta: { requireAuth: true, permLevel: 2 },
        //   component: () => import('@/views/Partnership')
        // },
        // {
        //   path: '/quote',
        //   name: 'Quote',
        //   meta: { requireAuth: true, permLevel: 2 },
        //   component: () => import('@/views/Quote')
        // },
        {
           path: '/clients',
           name: 'Clients',
           meta: { requireAuth: true, permLevel: 2 },
           component: () => import('@/views/Clients')
        }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/sessions/Sessions'),
      redirect: '/login',
      children: [
        {
          path: '/login/sso/:provider',
          name: 'login-sso',
          meta: { requireAuth: false },
          component: () => import('@/views/sessions/SSO.vue')
        }, {
          path: '/register/sso/:provider',
          name: 'register-sso',
          meta: { requireAuth: false },
          component: () => import('@/views/sessions/SSO.vue')
        }, {
          path: '/register/:token',
          name: 'register',
          meta: { requireAuth: false },
          component: () => import('@/views/sessions/Register.vue')
        },
        {
          path: '/login',
          name: 'login',
          meta: { requireAuth: false },
          component: () => import('@/views/sessions/SignIn')
        },
        {
          path: '/landing/azure/',
          name: 'Landing-Azure',
          meta: { requireAuth: false },
          component: () => import('@/views/sessions/Subscription')
        },
        {
          path: '/subscription/azure/activate',
          name: 'Sub-SSO-Azure',
          meta: { requireAuth: false },
          component: () => import('@/views/sessions/Subscription')
        },
        {
          path: '/subscription/aws/register',
          name: 'Sub-SSO-Aws',
          meta: { requireAuth: false },
          component: () => import('@/views/sessions/Subscription')
        },
        {
          path: '/forgot',
          name: 'forgot',
          meta: { requireAuth: false },
          component: () => import('@/views/sessions/Forgot')
        },
        {
          path: '/reset-password',
          name: 'reset-password',
          meta: { permLevel: 0 },
          component: () => import('@/views/sessions/ResetPassword')
        },
        {
          path: '/communication/:token',
          name: 'Communication',
          meta: { requireAuth: false },
          component: () => import('@/views/sessions/Communication.vue')
        }
        // {
        //   path: '*',
        //   name: 'error',
        //   meta: { requireAuth: false },
        //   redirect: '/'
        // }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true)
  }
  if (to.path.startsWith("/pentest/detail/")) {
    const pentestId = to.params.id
    const fileId = to.params.fileId
    if (to.path === `/pentest/detail/${pentestId}/files/${fileId}`) {
      sessionStorage.nextAfter = to.path
    }
  }
  if (to.path.startsWith("/communication")) {
    return next()
  }

  const user = TokenService.getUser()
  const profile = UserService.getLocalProfile()
  // const loggedIn = localStorage.getItem('user')

  if (user && profile === null) {
    UserService.getProfile().then((res) => {
      UserService.setLocalProfile(res)
      window.location.reload()
    })
  }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (to.meta.requireAuth) {
    if (!user) {
      next('/login')
    } else {
      // Permission control
      if (profile) {
        const userRole = profile.role
        let userPerm = 0
        if (userRole === 'Client Admin') {
          userPerm = 1
        }
        if (userRole === 'Partner') {
          userPerm = 2
        }
        if (userRole === 'Superuser') {
          userPerm = 4
        }
        if (to.meta.permLevel <= userPerm) {
          next()
        } else {
          return next('/dashboard')
        }
      }
    }
  }
  next()
})

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById('loading_wrap')
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = 'none'
  // }
  // Complete the animation of the route progress bar.

  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 30000)

  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   store.dispatch('changeSidebarProperties')
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch('changeSecondarySidebarProperties')
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch('changeCompactSidebarProperties')
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch('changeSecondarySidebarProperties')
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false
  // }
})

export default router

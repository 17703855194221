// Locale
// import { en } from '../../locale'
import colors from 'vuetify/lib/util/colors'
export const themeConfig = {
  disableCustomizer: false, // options[Boolean] : true, false(default)

  rtl: false, // options[Boolean] : true, false(default)
  verticalSidebarMini: false, // options[Boolean] : true, false(default)
  verticalSidebarDrawer: true, // options[Boolean] : true, false(default)
  verticalCompactSidebarDrawer: true, // options[Boolean] : true, false(default)
  verticalSaasSidebarDrawer: true, // options[Boolean] : true, false(default)
  showBreadcrumb: true, // options[Boolean] : true, false(default)

  layout: 'VerticalWhiteSidebar', // VerticalCompactSidebar,VerticalSaasLayout,VerticalSidebar,HorizontalBar
  isLoading: false,

  isDark: true,
  verticalSidebarDrawerColor: '#432461', // primary,white,sucsess,warning,etc
  appBarColor: 'white' // primary,white,sucsess,warning,etc
}

// const mq = window.matchMedia('(prefers-color-scheme: dark)')

// export const vuetify = new Vuetify({
//   theme: { dark: mq.matches }
// })

// console.log(mq)

// mq.addEventListener('change', e => {
//   console.log(mq)
//   this.$vuetify.theme.dark = e.matches
// })

export const themePreset = {
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920
    }
  },

  rtl: false,

  theme: {
    // dark: mq.matches,

    default: 'light',
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined
    },
    themes: {
      light: {
        primary: '#432461', // colors.blue,
        lightPrimary: '#F5F0F9',
        secondary: '#2D2933',
        success: colors.green,
        danger: colors.red,
        warning: colors.orange,
        info: '#28AAD2',
        background: '#F2F7FC',
        grey: '#EDEDED',
        green: '#C8E6C9',
        yellow: '#FCCB65',
        blue: '#9DDAFF'
      },
      dark: {
        primary: '#432461',
        lightPrimary: '#302f31',
        secondary: '#2D2933',
        success: colors.green,
        danger: colors.red,
        warning: colors.orange,
        info: '#28AAD2',
        background: '#05090c',
        green: colors.green,
        grey: '#423f3f',
        yellow: '#FCCB65',
        blue: '#0070C0'
      }
    }
  }
}
